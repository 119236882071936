import { __assign, __rest } from "tslib";
import React from 'react';
import classNames from 'classnames';
import { toDashCase } from './utils/strings';
/**
 * Actually parses the theme options
 */
export var parseThemeOptions = function (theme) {
    var themeItems = Array.isArray(theme) ? theme : [theme];
    return themeItems
        .filter(function (v) { return !!v; })
        .map(function (v) { return "mdc-theme--" + toDashCase(v); });
};
/**
 * HOC that adds themeability to any component
 */
export var withTheme = function (Component) {
    var HOC = function (_a) {
        var theme = _a.theme, className = _a.className, rest = __rest(_a, ["theme", "className"]);
        if (theme) {
            var classes = classNames(className, parseThemeOptions(theme));
            return React.createElement(Component, __assign({ className: classes }, rest));
        }
        return React.createElement(Component, __assign({ className: className }, rest));
    };
    HOC.displayName = 'withTheme';
    return HOC;
};
