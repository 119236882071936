/* istanbul ignore file */
export var eventsMap = {
    blur: 'onBlur',
    cancel: 'onCancel',
    click: 'onClick',
    close: 'onClose',
    contextmenu: 'onContextMenu',
    copy: 'onCopy',
    cut: 'onCut',
    auxclick: 'onAuxClick',
    doubleclick: 'onDoubleClick',
    dragend: 'onDragEnd',
    dragstart: 'onDragStart',
    drop: 'onDrop',
    focus: 'onFocus',
    input: 'onInput',
    invalid: 'onInvalid',
    keydown: 'onKeyDown',
    keypress: 'onKeyPress',
    keyup: 'onKeyUp',
    mousedown: 'onMouseDown',
    mouseup: 'onMouseUp',
    paste: 'onPaste',
    pause: 'onPause',
    play: 'onPlay',
    pointercancel: 'onPointerCancel',
    pointerdown: 'onPointerDown',
    pointerup: 'onPointerUp',
    ratechange: 'onRateChange',
    reset: 'onReset',
    seeked: 'onSeeked',
    submit: 'onSubmit',
    touchcancel: 'onTouchCancel',
    touchend: 'onTouchEnd',
    touchstart: 'onTouchStart',
    volumechange: 'onVolumeChange',
    abort: 'onAbort',
    animationend: 'onAnimationEnd',
    animationiteration: 'onAnimationIteration',
    animationstart: 'onAnimationStart',
    canplay: 'onCanPlay',
    canplaythrough: 'onCanPlayThrough',
    drag: 'onDrag',
    dragenter: 'onDragEnter',
    dragexit: 'onDragExit',
    dragleave: 'onDragLeave',
    dragover: 'onDragOver',
    durationchange: 'onDurationChange',
    emptied: 'onEmptied',
    encrypted: 'onEncrypted',
    ended: 'onEnded',
    error: 'onError',
    gotpointercapture: 'onGotPointerCapture',
    load: 'onLoad',
    loadeddata: 'onLoadedData',
    loadedmetadata: 'onLoadedMetadata',
    loadstart: 'onLoadStart',
    lostpointercapture: 'onLostPointerCapture',
    mousemove: 'onMouseMove',
    mouseout: 'onMouseOut',
    mouseover: 'onMouseOver',
    playing: 'onPlaying',
    pointermove: 'onPointerMove',
    pointerout: 'onPointerOut',
    pointerover: 'onPointerOver',
    progress: 'onProgress',
    scroll: 'onScroll',
    seeking: 'onSeeking',
    stalled: 'onStalled',
    suspend: 'onSuspend',
    timeupdate: 'onTimeUpdate',
    toggle: 'onToggle',
    touchmove: 'onTouchMove',
    transitionend: 'onTransitionEnd',
    waiting: 'onWaiting',
    wheel: 'onWheel',
    mouseenter: 'onMouseEnter',
    mouseleave: 'onMouseLeave',
    pointerenter: 'onPointerEnter',
    pointerleave: 'onPointerLeave',
    change: 'onChange',
    select: 'onSelect',
    beforeinput: 'onBeforeInput',
    compositionend: 'onCompositionEnd',
    compositionstart: 'onCompositionStart',
    compositionupdate: 'onCompositionUpdate'
};
