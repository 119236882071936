import { __assign, __read, __spread } from "tslib";
import { useEffect, useState, useRef, useMemo } from 'react';
import classNames from 'classnames';
import { eventsMap } from './utils/events-map';
import { toCamel } from './utils/strings';
import { handleRef } from './component';
var reactPropFromEventName = function (evtName) {
    return eventsMap[evtName] || evtName;
};
var FoundationElement = /** @class */ (function () {
    function FoundationElement(onChange) {
        this._classes = new Set();
        this._events = {};
        this._style = {};
        this._props = {};
        this._ref = null;
        this._onChange = null;
        this._onChange = onChange;
        this.onChange = this.onChange.bind(this);
        this.addClass = this.addClass.bind(this);
        this.removeClass = this.removeClass.bind(this);
        this.hasClass = this.hasClass.bind(this);
        this.setProp = this.setProp.bind(this);
        this.getProp = this.getProp.bind(this);
        this.removeProp = this.removeProp.bind(this);
        this.setStyle = this.setStyle.bind(this);
        this.addEventListener = this.addEventListener.bind(this);
        this.removeEventListener = this.removeEventListener.bind(this);
        this.setRef = this.setRef.bind(this);
    }
    FoundationElement.prototype.onChange = function () {
        this._onChange && this._onChange();
    };
    FoundationElement.prototype.destroy = function () {
        var _this = this;
        this._onChange = null;
        this._events = {};
        this._style = {};
        this._props = {};
        this._classes = new Set();
        setTimeout(function () {
            _this._ref = null;
        });
    };
    /**************************************************
     * Classes
     **************************************************/
    FoundationElement.prototype.addClass = function (className) {
        if (!this._classes.has(className)) {
            this._classes.add(className);
            this.onChange();
        }
    };
    FoundationElement.prototype.removeClass = function (className) {
        if (this._classes.has(className)) {
            this._classes.delete(className);
            this.onChange();
        }
    };
    FoundationElement.prototype.hasClass = function (className) {
        return this._classes.has(className);
    };
    /**************************************************
     * Props
     **************************************************/
    FoundationElement.prototype.setProp = function (propName, value, silent) {
        if (silent === void 0) { silent = false; }
        if (this._props[propName] !== value) {
            this._props[propName] = value;
            !silent && this.onChange();
        }
    };
    FoundationElement.prototype.getProp = function (propName) {
        return this._props[propName];
    };
    FoundationElement.prototype.removeProp = function (propName) {
        if (this._props[propName] !== undefined) {
            delete this._props[propName];
            this.onChange();
        }
    };
    FoundationElement.prototype.props = function (propsToMerge) {
        var _this = this;
        var _a = propsToMerge.className, className = _a === void 0 ? '' : _a, _b = propsToMerge.style, style = _b === void 0 ? {} : _b;
        // handle merging events
        // the foundation should be able to pass something onClick as well as a user
        // This wraps them in a function that calls both
        var mergedEvents = Object.entries(propsToMerge).reduce(function (acc, _a) {
            var _b = __read(_a, 2), key = _b[0], possibleCallback = _b[1];
            var existingCallback = _this._events[key];
            if (typeof possibleCallback === 'function' &&
                typeof existingCallback === 'function') {
                var wrappedCallback = function (evt) {
                    existingCallback(evt);
                    return possibleCallback(evt);
                };
                acc[key] = wrappedCallback;
            }
            return acc;
        }, __assign({}, this._events));
        // handle className
        var mergedClasses = classNames(className, __spread(this._classes));
        // handle styles
        var mergedStyles = __assign(__assign({}, this._style), style);
        return __assign(__assign(__assign(__assign({}, propsToMerge), this._props), mergedEvents), { style: mergedStyles, className: mergedClasses });
    };
    /**************************************************
     * Styles
     **************************************************/
    FoundationElement.prototype.setStyle = function (propertyName, value) {
        propertyName = propertyName.startsWith('--')
            ? propertyName
            : toCamel(propertyName);
        if (this._style[propertyName] !== value) {
            this._style[propertyName] = value;
            this.onChange();
        }
    };
    /**************************************************
     * Events
     **************************************************/
    FoundationElement.prototype.addEventListener = function (evtName, callback) {
        var propName = reactPropFromEventName(evtName);
        if (this._events[propName] !== callback) {
            this._events[propName] = callback;
            this.onChange();
        }
    };
    FoundationElement.prototype.removeEventListener = function (evtName, callback) {
        var propName = reactPropFromEventName(evtName);
        if (this._events[propName]) {
            delete this._events[propName];
            this.onChange();
        }
    };
    /**************************************************
     * Refs
     **************************************************/
    FoundationElement.prototype.setRef = function (el) {
        if (el) {
            this._ref = el;
        }
    };
    Object.defineProperty(FoundationElement.prototype, "ref", {
        get: function () {
            return this._ref;
        },
        enumerable: false,
        configurable: true
    });
    return FoundationElement;
}());
export { FoundationElement };
var emitFactory = function (props) { return function (evtType, evtData, shouldBubble) {
    if (shouldBubble === void 0) { shouldBubble = false; }
    var evt;
    evt = new CustomEvent(evtType, {
        detail: evtData,
        bubbles: shouldBubble
    });
    // bugfix for events coming from form elements
    // and also fits with reacts form pattern better...
    // This should always otherwise be null since there is no target
    // for Custom Events
    Object.defineProperty(evt, 'target', {
        value: evtData,
        writable: false
    });
    Object.defineProperty(evt, 'currentTarget', {
        value: evtData,
        writable: false
    });
    // Custom handling for React
    var propName = evtType;
    props[propName] && props[propName](evt);
    return evt;
}; };
export var useFoundation = function (_a) {
    var foundationFactory = _a.foundation, inputProps = _a.props, elementsInput = _a.elements, api = _a.api;
    var _b = __read(useState(0), 2), setIteration = _b[1];
    var props = useRef(inputProps);
    props.current = inputProps;
    var elements = useMemo(function () {
        return Object.keys(elementsInput).reduce(function (acc, key) {
            acc[key] = new FoundationElement(function () {
                setIteration(function (val) { return val + 1; });
            });
            return acc;
        }, {});
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);
    var foundation = useMemo(function () {
        // init foundation
        var f = foundationFactory(__assign(__assign({}, elements), { getProps: function () { return props.current; }, emit: function () {
                var args = [];
                for (var _i = 0; _i < arguments.length; _i++) {
                    args[_i] = arguments[_i];
                }
                return emitFactory(props.current).apply(void 0, __spread(args));
            } }));
        // handle apiRefs
        api && handleRef(props.current.apiRef, api(__assign({ foundation: f }, elements)));
        return f;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(function () {
        var f = foundation;
        f.init();
        api && handleRef(props.current.apiRef, api(__assign({ foundation: f }, elements)));
        handleRef(props.current.foundationRef, f);
        return function () {
            f.destroy();
            handleRef(props.current.apiRef, null);
            handleRef(props.current.foundationRef, null);
            Object.values(elements).map(function (element) { return element.destroy(); });
            // @ts-ignore
            props.current = {};
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [foundation, elements]);
    return __assign({ foundation: foundation }, elements);
};
