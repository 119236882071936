import { __assign, __read, __rest, __spread, __values } from "tslib";
import React from 'react';
import classNamesFunc from 'classnames';
import { parseThemeOptions } from './with-theme';
export var Tag = React.forwardRef(function Tag(_a, ref) {
    var _b = _a.tag, TagEl = _b === void 0 ? 'div' : _b, theme = _a.theme, element = _a.element, rest = __rest(_a, ["tag", "theme", "element"]);
    var finalProps = element ? element.props(rest) : rest;
    var finalRef = element ? mergeRefs(ref, element.setRef) : ref;
    return React.createElement(TagEl, __assign({}, finalProps, { ref: finalRef }));
});
export var useClassNames = function (props, classNames) {
    return classNamesFunc.apply(void 0, __spread([props.className], (!!props.theme ? parseThemeOptions(props.theme) : []), (typeof classNames === 'function' ? classNames(props) : classNames)));
};
export var mergeRefs = function () {
    var refs = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        refs[_i] = arguments[_i];
    }
    return function (el) {
        var e_1, _a;
        try {
            for (var refs_1 = __values(refs), refs_1_1 = refs_1.next(); !refs_1_1.done; refs_1_1 = refs_1.next()) {
                var ref = refs_1_1.value;
                if (typeof ref === 'function') {
                    ref(el);
                }
                else if (ref && 'current' in ref) {
                    // @ts-ignore
                    ref.current = el;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (refs_1_1 && !refs_1_1.done && (_a = refs_1.return)) _a.call(refs_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
    };
};
export var handleRef = function (ref, value) {
    if (typeof ref === 'function') {
        ref(value);
    }
    else if (ref && 'current' in ref) {
        // @ts-ignore
        ref.current = value;
    }
};
export function createComponent(Component) {
    var ForwardComponent = React.forwardRef(Component);
    // Interestingly enough, we only need this declaration
    // for a generic placeholder for typescript inference,
    // we don't actually have to pay the penalty for using it at runtime :)
    var WrappedComponent = function (props, ref) {
        return React.createElement(React.Fragment, null);
    };
    WrappedComponent.displayName = Component.constructor.name || 'RMWCComponent';
    ForwardComponent.displayName = WrappedComponent.displayName;
    return ForwardComponent;
}
export function createMemoComponent(Component) {
    var Comp = createComponent(Component);
    return React.memo(Comp);
}
